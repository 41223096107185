import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import Draggable from 'react-draggable';
import Grid from '@mui/material/Grid';
import { DialogTitle } from '../../Dialog/ModalDialog';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import LeftArrow from '@mui/icons-material/ArrowBackIosNewRounded';
import RightArrow from '@mui/icons-material/ArrowForwardIosRounded';
import ZoomIn from '@mui/icons-material/ZoomInRounded';
import ZoomOut from '@mui/icons-material/ZoomOutRounded';

// import { ModalToaster } from '../../Toaster/Toaster';

function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props}/>
      </Draggable>
    );
}

export default function AttachmentViewer(props) {
    const { t } = useTranslation('translation');
    const { attachment, open, toggleDialog } = props;
    const [record, setRecord] = useState({});
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(1);
    const [scale, setScale] = useState(1.4);
    // const [systemMessage, setSystemMessage] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
            //pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url,).toString()
            let data = await axios.get('/api/document/' + attachment._id);
            setRecord(data.data);
        };
        fetchData();
    }, [attachment]);

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    };

    const handleTraverse = direction => {
        if (direction === 'next') {
            setPageNumber(pageNumber + 1);
        } else {
            setPageNumber(pageNumber - 1);
        }
    };

    const adjustScale = direction => {
        if (direction === 'in') {
            setScale(scale + 0.2);
        } else {
            setScale(scale - 0.2);
        }
    }

    return (
        <Dialog
            open={open}  
            onClose={() => toggleDialog()} 
            aria-labelledby="draggable-dialog-title" 
            PaperComponent={PaperComponent}
            fullWidth
            maxWidth='md'
            scroll='paper'
            sx={{ maxHeight: '90%'}}
        >
            <DialogTitle 
                id="draggable-dialog-title" 
                sx={{ m: 0, p: 1, cursor: 'move', backgroundColor: '#e5e3e3' }} 
                onClose={() => toggleDialog()}
                downloadRecord={attachment}
                >
                {attachment.name}
            </DialogTitle>
            <DialogContent dividers sx={{ p: 2 }}>
                {attachment.type === 'application/pdf' ?
                    <Document file={'data:application/octet-stream;base64,' + record} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} scale={scale}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                        />
                    </Document> :
                    <img 
                        src={"data:application/octet-stream;base64," + record} 
                        alt={document.name ?? ''}
                        height="auto" 
                        width="100%" 
                    />
                }
                {/* <ModalToaster alert={systemMessage} /> */}
            </DialogContent>
            <DialogActions sx={{ p: 1 }}>
                <Grid 
                    container 
                    spacing={0} 
                    direction='row'
                    justifyContent='space-between'
                >
                    <Grid item xs={1}>
                        <IconButton 
                            aria-label="delete line" 
                            onClick={() => adjustScale('out')}
                            disabled={scale <= 0.4}
                        >
                            <ZoomOut fontSize='large' />
                        </IconButton>
                    </Grid>
                    <Grid item fullWidth xs={10} sx={{ textAlign: 'center'}}>
                        <IconButton 
                            aria-label="delete line" 
                            onClick={() => handleTraverse('prev')}
                            disabled={pageNumber <= 1}
                        >
                            <LeftArrow />
                        </IconButton>
                        <span>{t('page')} {pageNumber} {t('of')} {numPages}</span>
                        <IconButton
                            aria-label="delete line" 
                            onClick={() => handleTraverse('next')}
                            disabled={pageNumber >= numPages}
                        >
                            <RightArrow />
                        </IconButton>
                    </Grid>
                    <Grid item xs={1} sx={{ textAlign: 'right' }}>
                        <IconButton
                            aria-label="delete line" 
                            onClick={() => adjustScale('in')}
                            disabled={scale >= 4.0}
                        >
                            <ZoomIn fontSize='large'/>
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogActions>
            {/* {pageNumber > 1 &&
                    <Button variant="contained" color="secondary" size="small" onClick={() => handleTraverse('prev')}>{'<'}</Button>
                }
                &nbsp;&nbsp;<p>Page {pageNumber} of {numPages}</p>&nbsp;&nbsp;
                {pageNumber < numPages &&
                    <Button variant="contained" color="secondary" size="small" onClick={() => handleTraverse('next')}>{'>'}</Button>
                }
                {/* <Button variant="contained" color="primary" size="small" onClick={this.documentClick}>{t('download')}</Button>
            </DialogActions> */}
        </Dialog>       
    );
};